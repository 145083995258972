<template>
  <LayoutMainLayout>
    <slot></slot>
  </LayoutMainLayout>
</template>

<script setup lang="ts">
// ----- Imports -----
import type { SettingsFetchResponse } from '~/interfaces/Settings';
import type { UserFetchResponse } from '~/interfaces/User';
import type { Wishlist } from '~/interfaces/Wishlist';
import { changeLocale } from '@formkit/vue';
import { currencies } from '~/models/currencies';

// ----- Stores & Reactive State -----
const settingsStore = useSettingsStore();

const experimentStore = useExperimentStore();
const authStore = useAuthStore();
const cartStore = useCartStore();
const checkoutStore = useCheckoutStore();
const wishlistStore = useWishlistStore();

const { ip } = storeToRefs(settingsStore);
const { cartUUID } = storeToRefs(cartStore);
const { variant } = storeToRefs(experimentStore);
const { userCountry } = storeToRefs(settingsStore);

const { locales, locale, setLocale } = useI18n();
const { isMobile, isDesktop, isApple } = useDevice();
const switchLocale = useSwitchLocalePath();
const route = useRoute();
const gtm = useGtm();
const cookie = useCookie('token');

// ----- Computed Properties -----
const lang = computed(() => locales.value.find((l) => l.code === locale.value)?.code ?? 'en');
const dir = computed(() => locales.value.find((l) => l.code === locale.value)?.dir ?? 'ltr');
useHead({
  htmlAttrs: {
    lang,
    dir,
  },
  meta: [{ name: 'og:locale', content: lang }],
});

// ----- API Calls & Side Effects -----
// Fetch user data if authenticated
if (cookie.value) {
  await useFetch<UserFetchResponse>('/api/me', {
    headers: {
      'Accept-Language': locale.value.split('-')[0],
    },
    onResponse({ response }) {
      authStore.setUser(response._data.data);
    },
  });

  await useFetch<Wishlist>('/api/wishlist', {
    headers: {
      'Accept-Language': locale.value.split('-')[0],
    },
    onResponse({ response }) {
      if (response.ok) {
        wishlistStore.setWishlistItems(response._data.data.products);
      }
    },
  });
}
// Fetch settings
useFetch<SettingsFetchResponse>('/api/settings', {
  key: 'settings',
  onResponse({ response }) {
    settingsStore.setSettings(response._data.data.settings);
  },
  transform(input) {
    return {
      ...(input as any),
      fetchedAt: new Date(),
      language: locale.value,
    };
  },
  getCachedData(key, nuxtApp) {
    const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key];

    if (!data) {
      return;
    }

    const expirationDate = new Date(data.fetchedAt);
    expirationDate.setTime(expirationDate.getTime() + 3600 * 1000);
    const isExpired = expirationDate.getTime() < Date.now();

    if (isExpired) {
      return;
    }

    if (locale.value !== data.language) {
      return;
    }

    return data;
  },
});

await useFetch('https://ipwho.is?fields=country,country_code,ip', {
  key: 'get-user-country',
  server: false,
  onResponse({ response }) {
    if (userCountry.value.countryCode !== '') return;

    settingsStore.setIp(response._data.ip);
    const userCountryCode = response._data.country_code;
    const matchedCurrency = currencies.find((c) => c.countryCode === userCountryCode);
    if (matchedCurrency) {
      settingsStore.setUserCountry({ country: response._data.country, countryCode: userCountryCode });
      settingsStore.setCurrency(matchedCurrency.name);

      const pathLocale = route.path.split('/')[1]; // Extract locale and country code
      const [language, countryCode] = pathLocale ? pathLocale.split('-') : [];

      if (pathLocale) {
        userCountryCode.toLowerCase() === 'ae'
          ? setLocale(pathLocale)
          : setLocale(`${pathLocale}-${userCountryCode.toLowerCase()}`);
      } else {
        userCountryCode.toLowerCase() === 'ae' ? setLocale('en') : setLocale(`en-${userCountryCode.toLowerCase()}`);
      }

      // userCountryCode.toLowerCase() === 'ae' ? setLocale('en') : setLocale(`en-${userCountryCode.toLowerCase()}`);
    } else {
      settingsStore.setUserCountry({ country: 'United Arab Emirates', countryCode: 'AE' });
      settingsStore.setCurrency('AED');
    }
  },
});

const cartID = useCookie<{ cartUUID: string }>('cart');
if (!cartID.value || cartID.value.cartUUID === null) {
  cartStore.resetCart(true);
} else {
  await cartStore.retrieveCart(locale.value);
}

// ----- Lifecycle Hooks -----
onMounted(() => {
  gtmPageView(); // Initial GTM page view event
});

// ----- Functions -----
async function gtmPageView() {
  gtm?.push({
    countryCode: userCountry.value.countryCode ?? 'AE',
    languageCode: lang.value,
    pageUrl: window.location.href,
    platformType: getPlatformType(),
    loginState: cookie ? 'Logged In' : 'Logged Out',
    cartSessionId: cartUUID.value ? cartUUID.value : 'No Cart Session',
    experiment_type: null,
    first_name: authStore.user?.first_name || checkoutStore.order?.user.first_name,
    last_name: authStore.user?.last_name || checkoutStore.order?.user.last_name,
    email: authStore.user?.email || checkoutStore.order?.user.email,
    phone_number: authStore.user?.phone_number || checkoutStore.order?.user.phone_number,
  });

  changeLocale(lang.value);

  if (route.query.promo_code) {
    cartStore.promoCode = route.query.promo_code.toString();
    await cartStore.applyPromoCode(cartStore.promoCode);
  }
}

function getPlatformType() {
  return isMobile ? (isApple ? 'Apple - Mobile' : 'Android - Mobile') : isDesktop ? 'Desktop' : 'Tablet';
}

// ----- Watchers -----
watch(
  () => route.path,
  () => {
    gtmPageView();
  },
);

// Function to update currency and locale based on the URL
function updateCurrencyAndLocaleFromURL() {
  if (settingsStore.userCountry.countryCode || settingsStore.ip) return;

  const pathLocale = route.path.split('/')[1]; // Extract locale and country code

  if (!pathLocale) return;

  const [language, countryCode] = pathLocale ? pathLocale.split('-') : [];

  if (!countryCode) return;

  const matchedCurrency = currencies.find((c) => c.countryCode.toUpperCase() === countryCode.toUpperCase());

  // Proceed with setting locale and currency if matched
  if (matchedCurrency && !settingsStore.userCountry.countryCode) {
    settingsStore.setUserCountry({
      country: matchedCurrency.country,
      countryCode: matchedCurrency.countryCode,
    });
    settingsStore.setCurrency(matchedCurrency.name);
  } else {
    settingsStore.setUserCountry({ country: 'United Arab Emirates', countryCode: 'AE' });
    settingsStore.setCurrency('AED');
  }
}

updateCurrencyAndLocaleFromURL();

const { t } = useI18n();

useHead(() => ({
  link: [
    {
      rel: 'canonical',
      href: 'https://biogena-me.com' + route.path,
    },
  ],
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | Biogena Middle East` : t('seo.home_title');
  },
}));
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
